import React, { useEffect, useState } from "react";
import { ChevronsLeft, ChevronsRight } from "react-feather";
import { cookiePolicy } from "../../jsondata/termsdata";
import PageLayout from "../components/layouts/PageLayout";
import TermLink from "../components/terms/TermLink";
import TermSection from "../components/terms/TermSection";
import TermsHeader from "../components/terms/TermsHeader";
const Terms = () => {
    const [pauseSpy, setPauseSpy] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            spyScrolling();
        }, 300);
        document.addEventListener("scrollToLink", function (evnt) {
            console.log(evnt.target.id);
        });
    }, []);

    const toTitleCase = (phrase) => {
        return phrase
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const generateID = (title = "") => {
        return String(toTitleCase(title))
            .toLowerCase()
            .replace(/[^a-zA-Z ]/g, "")
            .split(" ")
            .join("-");
    };

    const scrollSidebar = (containerElement, childElement) => {
        if (containerElement && childElement)
            containerElement.scrollTo({
                top: childElement.offsetTop - 100,
                left: 0,
                behavior: "smooth",
            });
    };

    const spyScrolling = () => {
        document
            .getElementById("terms-content")
            .addEventListener("scroll", scrollFn);
    };
    const scrollFn = (e) => {
        const sections = document.querySelectorAll(".term-section");
        if (!pauseSpy) {
            const scrollPos =
                document.documentElement.scrollTop || e.srcElement.scrollTop;
            console.log("eeee", scrollPos);
            for (let s in sections) {
                console.log(
                    "scrollPos",
                    sections.hasOwnProperty(s),
                    sections[s].offsetTop,
                    scrollPos
                );
                if (
                    sections.hasOwnProperty(s) &&
                    sections[s].offsetTop - 200 <= scrollPos
                ) {
                    const id = sections[s].id;
                    let activeEl = document.querySelector(".active");
                    if (activeEl) {
                        activeEl.classList.remove("active");
                    }

                    let linkEl = document.getElementById(`term-link-${id}`);
                    if (linkEl) {
                        linkEl.classList.add("active");
                        scrollSidebar(
                            document.getElementById("term-sidebar"),
                            linkEl
                        );
                    }
                }
            }
        }
    };
    return (
        <PageLayout showFooter={false}>
            <TermsHeader />
            <div className={`pt-[90px] flex !flex-row min-h-screen relative`}>
                <div
                    className={`bg-white duration-200 ${
                        open ? "w-full" : "w-0"
                    } lg:w-[304px] py-6 h-full max-h-[calc(100vh-90px)] overflow-auto border-r fixed top-90 left-0 active `}
                    id="term-sidebar"
                >
                    {cookiePolicy.map((term) => {
                        return (
                            <TermLink
                                setOpen={setOpen}
                                setPauseSpy={setPauseSpy}
                                id={generateID(term?.title)}
                                title={term?.title}
                                key={`term-${generateID(term?.title)}`}
                            />
                        );
                    })}
                </div>

                <div
                    className={`w-10 h-10 duration-200 bg-secondary shadow-lg text-white flex items-center justify-center absolute z-20 md:hidden 
    ${open ? "ml-[calc(100vw-40px)] mt-0" : "mt-5 "}`}
                    onClick={() => {
                        setOpen((open) => !open);
                    }}
                >
                    {open ? (
                        <ChevronsLeft className="w-5 rounded" />
                    ) : (
                        <ChevronsRight className="w-5 rounded" />
                    )}
                </div>

                <div
                    className="w-full lg:w-[calc(100%-304px)] pl-11 pr-6 py-6 lg:p-6 flex flex-col lg:ml-auto overflow-auto max-h-[calc(100vh-90px)]"
                    id="terms-content"
                >
                    <h2 className="text-2xl font-bold text-[#202020] mb-9">
                        COOKIE POLICY
                    </h2>
                    {cookiePolicy?.map((term) => {
                        return (
                            <TermSection
                                id={generateID(term?.title)}
                                title={term?.title}
                                content={term?.content}
                            />
                        );
                    })}
                    <div className="min-h-[400px]"></div>
                </div>
            </div>
        </PageLayout>
    );
};

export default Terms;
